<template>
  <div>
    <div>
      <CallSidebar v-if="material && !terminal_error"
                   view="selfstudy"
                   :linkchat="false"
                   @stop="leave"/>
    </div>
    <section class="lesson">
      <div v-if="!terminal_error">
        <div v-if="loading" class="vh-100 d-flex justify-content-center align-items-center">
          <Loader/>
        </div>
        <template v-else>
          <div class="container-left">
            <div class="lesson-left-part">
              <Canvas v-if="canvasLaunched"
                      ref="canvascomponent"
                      :slides="slides"
                      :userId="userId"
                      view="selfstudy"
                      :homeworkStudentId="homeworkStudentId"
                      :linkchat="false"
                      :cards="cards"
                      :showChatAndWords="false"
                      @stop="stop"
                      :rates="false"/>
            </div>
          </div>
        </template>
      </div>
      <div class="container terminal-error pt-5" v-if="terminal_error">
        <div class="spacecard text-center py-5">
          <div class="emoji">😢</div>
          <div class="text">{{ terminal_error }}</div>
        </div>
      </div>
      <LessonPresentationPart :host="host"
                              view="selfstudy"
                              :userId="userId"/>
    </section>
  </div>
</template>

<script>

import Canvas from "@/views/parts/class/Canvas"
import CallSidebar from "@/views/parts/general/CallSidebar"
import Loader from "@/views/parts/general/Loader"
import { LessonsService, StudentPageService } from '@/services/api.service'
import LessonPresentationPart from "../../parts/class/LessonPresentationPart"
import {mapActions, mapState} from 'vuex'

export default {
  name: 'SelfStudyLesson',
  components: {
    Canvas,
    CallSidebar,
    LessonPresentationPart,
    Loader,
  },
  metaInfo() {
    return {
      title: this.material ? this.material.title : this.$t('lesson'),
    }
  },
  data() {
    return {
      material: null,
      studyLesson: null,
      userId: null,
      canvasLaunched: false,
      loading: true,
      slides: [],
      terminal_error: null,
      mode: "slide",
      homeworkStudentId: this.$route.params.studentid ? this.$route.params.studentid : null,
      showPresentation: false,
      words: [],
      cards: [],
      materialId: this.$route.params.id,
    }
  },
  computed: {
    ...mapState({
      host:          state => state.call.host,
      selectedUser:  state => state.call.selectedUser,
      bottomBurger:  state => state.call.bottomBurger,
    }),
  },
  methods: {
    ...mapActions([
      'getUserTasks',
    ]),
    async stop() {
      if(this.studyLesson) {
        if(this.host) {
          await StudentPageService.selfStudyReviewed(this.studyLesson.id)
        } else {
          await StudentPageService.selfStudyFinished(this.studyLesson.id)
        }
      }
      this.leave()
    },
    leave() {
      if(this.host && this.homeworkStudentId) {
        location.href = `/instructor/students/${this.homeworkStudentId}/history`
        return
      }
      location.href = '/'
    },
    launchMedia() {
      this.loading = false
    },
    async getSlides() {
      try {
        let res = await LessonsService.getSlidesForLesson(this.materialId, 'selfstudy', this.homeworkStudentId)
        this.$store.commit('setSlides', res.data.data.slides)
        this.$store.commit('setLessonWords', res.data.data.material.words)
        this.$store.commit('setMaterial', res.data.data.material)
        this.$store.commit('setLessonCards', res.data.data.cards)
        this.slides = res.data.data.slides
        this.words = res.data.data.material.words
        this.cards = res.data.data.cards
        this.school = res.data.data.school
        this.material = res.data.data.material
        this.studyLesson = res.data.data.studyLesson
        this.$store.commit('setSelfStudyReviewed', this.studyLesson.reviewed)
        if([1,3,4].includes(this.$store.state.user.primary_role_id)) {
          this.$store.commit('setHost', true)
          if(this.homeworkStudentId) {
            this.$store.commit('setHomeworkStudentId', this.homeworkStudentId)
            this.getUserTasks({user_id: this.homeworkStudentId, id: this.materialId, type: 'selfstudy' })
          }
        }
        this.$nextTick(() => {
          this.canvasLaunched = true
          this.$store.commit('toggleLessonMode', 'slide')
          this.$store.commit('expandLessonMode', 'slide')
        })
        return true
      } catch (e) {
        console.log(e)
        this.loading = false
        return false
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('setSelfStudyReviewed', false)
    this.$store.commit('setHomeworkStudentId', null)
  },
  async mounted() {
    if (!await this.getSlides()) {
      this.terminal_error = this.$t('lesson_not_found')
      return
    }

    this.$store.commit('setIsHomework', false)
    this.$store.commit('setFollowed', false)
    this.$store.commit('setSocket', null)
    this.$store.commit('setViewMode', 'selfstudy')

    if(!this.homeworkStudentId) {
      this.getUserTasks({ id: this.materialId, type: 'selfstudy' })
    }
    this.$store.commit('setMaterialId', this.materialId)
    this.loading = false
  },
}
</script>

<style scoped lang="scss">

.container-left {
  padding-right: 20px;
  max-width: 1120px;
}

@media(max-width: 768px) {
  .mob_v {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .container-left {
    //margin-left: 20px !important;
    padding: 0 18px;
    &.sidebarOpened {
      margin-left: 75px !important;
    }
  }
}

.lesson {
  min-height: 100vh;
  background: $gray-back2;
}

.terminal-error {
  .emoji {
    font-size: 50px;
  }

  .text {
    font-size: 20px;
  }
}

</style>
